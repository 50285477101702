@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap);
* {
  font-family: 'Nunito', sans-serif;
}

.mail_compose {
  position: fixed;
  height: auto;
  bottom: 0;
  right: 12px;
  width: 71vh;
  padding: 30px;
  background: #939580;
  border-radius: 12px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  font-weight: bold;
  border-top-right-radius: 0;
}
